import {useState, useRef, useMemo} from 'react';


const StarRating = ({rate, onSaveRating, titleId}) => {
  const [rating, setRating] = useState(rate);
  const [hoverRating, setHoverRating] = useState(0);
  const divRef = useRef();
  const onMouseEnter = (index) => {
    setHoverRating(index);
  };
  const onMouseLeave = () => {
    setHoverRating(0);
  };

  const onClickRating =(index) => {
    //Call the function to save the rating from the top-component.
    onSaveRating(index)
    // Then update the internal rate, show the stars show new rate.
    setRating(index)
  };


  function RatingIcon(props) {
    const {
      index,
      rating,
      hoverRating,
      onMouseEnter,
      onMouseLeave,
      titleId,
    } = props;
    const fill = useMemo(() => {
      if (hoverRating >= index) {
        return 'red';
      } else if (!hoverRating && rating >= index) {
        return 'red';
      } else if (rating===null){
        return 'none';
      }
      return '#ffbbbb';
    }, [rating, hoverRating, index]);

    const half = useMemo(() => {
      if ((index) % 1 === 0) {
        return '12 0 12 24';
      } else {
        return '0 0 12 24';
      }
    }, [index]);


    return (
        <div
          key={'StarRate' + titleId + '-' + index}
          className="cursor-pointer"
          onMouseEnter={() => onMouseEnter(index)}
          onMouseLeave={() => onMouseLeave()}
          onClick={() => onClickRating(index)}
          >
          <StarIcon fill={fill} half={half}/>
        </div>
    )
  }

  function StarIcon(props) {
    const { fill = 'none', half='' } = props;
    return (
      <svg pointerEvents="none" className="w-6 h-6" fill={fill} stroke="currentColor" viewBox={half} xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path>
      { rating==='0.0' ? <><path fill='red' d="M12 6a3.5 3.5 0 0 0-3.5 3.5 1 1 0 0 0 2 0A1.5 1.5 0 1 1 12 11a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.16A3.49 3.49 0 0 0 12 6z"/><circle cx="12" cy="17" r="1"/></> : <></>}

      </svg>
    );
  }
  //{ rating ===null || rating==='0.0' ? <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" fill='white' d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path> : <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path>}

//{ rating==='0.0' & fill==='none' ? <><path fill='red' d="M12 6a3.5 3.5 0 0 0-3.5 3.5 1 1 0 0 0 2 0A1.5 1.5 0 1 1 12 11a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.16A3.49 3.49 0 0 0 12 6z"/><circle cx="12" cy="17" r="1"/></> : <></>}
  if(hoverRating>0 && divRef.current && divRef.current.matches(':hover') === false)
    setHoverRating(0);

  return(
    <div className="box flex" ref={divRef}>
      {[0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5].map((index) => {
        return (
          <RatingIcon
            index={index}
            rating={rating}
            hoverRating={hoverRating}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onSaveRating={onSaveRating}
            key={'SR'+titleId+'-'+index}/>
        )
      })}
    </div>
  );

}

export default StarRating;
