import { useState, useEffect, useContext} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MoviesByDirector from './MoviesByDirector';
import MovieDetails from './MovieDetails';
import UserContext from './UserContext';
import { Button } from 'react-bootstrap';
import AddTipModal from '../Components/AddTipModal';
import AddToListDropDown from '../Components/AddToListDropDown';
import { useApi } from '../api/useApi';
import { fetchResource, postResource } from '../api/api';


const MovieDetailsPage = () => {
  const navigate = useNavigate();
  const { movieId } = useParams();
  const [movie, setMovie] = useApi(() => fetchResource('getMovieDetails/'+movieId));
  const [activeDirectorId, setActiveDirectorId] = useState('');
  const [directors, setDirectors] = useState([]);
  const [userContext] = useContext(UserContext);


  // This function is called whenever the search variable is changed.
  useEffect(()=>{
    // First get info of movie, then get director info.
    if (movieId){
      setMovie();
    }
  },[movieId]);

  useEffect(()=>{

    if(movie.data){
      if (movie.data.Directors){
        setDirectors(movie.data.Directors)
        if (movie.data.Directors[0]){
          setActiveDirectorId(movie.data.Directors[0].Id)
        }
      }
    }
  },[movie]);

  function updateDirectorId(id){
    setActiveDirectorId(id);
  }



  return (
    <div>

      { movie.data ? <MovieDetails movie={movie.data} /> : '' }
      <AddToListDropDown titleId={movieId}/>
      { userContext.lists && userContext.userId ? <div className="mb-2"><AddTipModal movie={movie.data}/></div> : <></>}

      <br/>
      <h3>Regisseur(s):</h3>
      {directors ? directors.map((Director, index) => (<Button key={'DB'+index} onClick={() => updateDirectorId(Director.Id)}>{Director.Name}</Button>)):''}
      <MoviesByDirector directorId={activeDirectorId} sort={'Year'}/>
    </div>
  );
};

export default MovieDetailsPage;
