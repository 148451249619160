import { useContext } from 'react';
import { CheckCircleFill, Tv } from 'react-bootstrap-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button} from 'react-bootstrap';
import { Tooltip } from "react-tooltip";
import { useMediaQuery } from 'react-responsive';
import { getUserRateFromMovie } from '../functions';
import StarRating from './StarRating';
import UserContext from './UserContext';
import { postResource } from '../api/api';
import { formatRate } from '../functions';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-tooltip/dist/react-tooltip.css';

const MovieSearchResultList = ({movies, sort, showTips, setDeleteTip}) => {

  const [userContext] = useContext(UserContext);
  // For small screens, we can hide the extra columns
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)'});

  var hideExtendedColumns = true;
  if (isDesktopOrLaptop) {
    hideExtendedColumns = false;
  }


  const columns = [{
    dataField: 'Id',
    hidden: true
  },
  {
    dataField: 'Rating',
    text: '',
    formatter: MovieisSeenCellFormater,
    headerStyle: () => {
      return { width: "30px" };
    },
    sort: true,
    sortFunc: (a, b, order, dataField, rowA, rowB) => {
      if (order === 'asc') {
        return (rowA.AverageRate - rowB.AverageRate);
      }
       return (rowB.AverageRate - rowA.AverageRate);// desc
  }
  }, {
    dataField: 'Directors',
    text: 'Regisseur',
    formatter: DirectorCellFormater
  }, {
    dataField: 'Title',
    isDummyField: true,
    text: 'Titel',
    formatter: MovieTitleCellFormater
  }, {
    dataField: 'Year',
    text: 'Jaar',
    sort: true,
    headerStyle: () => {
      return { width: "50px" };
    }
  },{
    dataField:'Rating2',
    isDummyField: true,
    hidden: showTips,
    text: 'Zelf',
    formatter: OwnScoreCellFormater,
    headerStyle: () => {
      if(hideExtendedColumns){
        return { width: "50px" };
      } else {
        return { width: "150px" };
      }
    },
    hidden: !(userContext.userId>0),
    sort: true,
    sortFunc: (a, b, order, dataField, rowA, rowB) => {
      if (order === 'asc') {
        return getUserRateFromMovie(rowA,userContext.userId,false) - getUserRateFromMovie(rowB,userContext.userId,false);
      }
       return getUserRateFromMovie(rowB,userContext.userId,false) - getUserRateFromMovie(rowA,userContext.userId,false); // desc
     }
  },
  {
    dataField: 'IMDBRate',
    text: 'IMDB',
    sort: true,
    headerStyle: () => {
      return { width: "50px" };
    }
  }, {
    dataField: 'RunTime',
    text: 'Lengte',
    headerStyle: () => {
      return { width: "80px" };
    },
    hidden: hideExtendedColumns
  }];


  function MovieTitleCellFormater(cell, row, rowIndex, formatExtraData) {
     return (
      <div key={'TitleDiv' + row.Id}>
        <a key={'TitleLink' + row.Id} href={"/#/Movie/" + row.Id}> {row.Title} </a>{row.Type!==1 ? <Tv key={'TVIcon' + row.Id}/> : <></>}<br />
        {row.OriginalTitle}
      </div>
    );
  };

  function MovieisSeenCellFormater(cell, row, rowIndex, formatExtraData) {
    return (
     <div>
       { row.Rating && row.Rating.length > 0? <><div key={'RatingDiv' + row.Id} data-tooltip-id={'Rating' + row.Id}><CheckCircleFill /></div> <Tooltip id={'Rating' + row.Id} place="top" effect="solid">{row.Rating.map((Rate, index) => (<div key={'isSeenRates'+row.Id.toString() + Rate.Name.toString()}>{Rate.Name} - {formatRate(Rate.Rate)}</div>))}</Tooltip>< />: <div />}
    </div>
    );
  };

  function DirectorCellFormater(cell, row, rowIndex, formatExtraData) {
     return (
       <>{ row.Directors ? <>{row.Directors.slice(0,5).map((Director, index) => (<div key={'Directors2'+Director.Id + '-' + index} className="inline"><a key={'Directors'+Director.Id + '-' + index} href={"/#/Director/" + Director.Id}>{Director.Name}</a>&nbsp;</div>))}< />: <div key={"directors"+rowIndex}/>}</>
     );
   };

   function OwnScoreCellFormater(cell, row, rowIndex, formatExtraData) {
     const onSaveRating = async (rate) => {
       postResource('addRate', { titleId: row.Id, rate: rate });
     };
      return (
        <>
        { isDesktopOrLaptop ? <StarRating key={'OwnRAte'+row.Id} rate={getUserRateFromMovie(row,userContext.userId)} onSaveRating={onSaveRating}/> : formatRate(getUserRateFromMovie(row,userContext.userId)) }
        </>
      );
    };

    const expandRow = {
      renderer: row => (
        <><div key={"expand"+row.Id}>{row.From_UserName}: {row.Content}</div><div key={"expand2"+row.Id}><Button key={"expand3"+row.Id}variant="primary" onClick={() => deleteTip(row.TipId)}>Verwijder</Button></div></>
      ),
      // Automatically expand all rows by getting an array of all row Ids
      expanded: movies.map(function(movies) { return movies['Id']; })
    };

    function deleteTip(id){
      setDeleteTip(id);
    }

  return (
    <BootstrapTable keyField='Id' data={ movies } columns={ columns } sort={ { dataField: sort, order: 'desc' } } expandRow={ showTips ? expandRow : {expanded:Array()} } striped condensed={true} bootstrap4={true}/>
  );
};

export default MovieSearchResultList;

MovieSearchResultList.defaultProps = {
    sort: 'Rating',
    showTips: false
  }
