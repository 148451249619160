import { useEffect} from 'react';
import { useParams } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { useApi } from '../api/useApi';
import { postResource } from '../api/api';

const SearchDirectorResults = () => {
  const [directors, setDirectors] = useApi(() => postResource('searchDirector', {search: search}));
  const { search } = useParams();
  const columns = [{
    dataField: 'Name',
    text: 'Regisseur',
    formatter: DirectorCellFormater,
  }];

  function DirectorCellFormater(cell, row, rowIndex, formatExtraData) {
     return (
       <a href={"#/Director/"+row.Id}>{row.Name}</a>
     );
   };

  // This function is called whenever the search variable is changed.
  useEffect(()=>{
    if (search){
      setDirectors();
    }
  },[search]);

  return (
    <BootstrapTable keyField='Id' data={ directors.data ? directors.data : [] } columns={ columns } striped condensed={true} bootstrap4={true}/>
  );
};

export default SearchDirectorResults;
