import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Form } from 'react-bootstrap';
import ViewedMoviesGrid from './ViewedMoviesGrid';
import { CaretDownFill,CaretUpFill } from 'react-bootstrap-icons';
import GenreFilter from './GenreFilter';

import ReactBootstrapSlider from 'react-bootstrap-slider';
import "bootstrap-slider/dist/css/bootstrap-slider.css"

const UserPage = () => {
  const { userId, userName } = useParams();
  const [ rateFilter, setRateFilter ] = useState([0,5]);
  const [ order, setOrder ] = useState('LastViewed');
  const [ expanded, setExpanded ] = useState(false);
  const [ selectedGenres, setSelectedGenres ] = useState( []);
  const [ typeFilter,setTypeFilter] = useState([1,2,3]);

  useEffect(() => {
    if(sessionStorage.getItem('rateFilter')){
      setRateFilter(JSON.parse(sessionStorage.getItem('rateFilter')));
    }
    if(sessionStorage.getItem('order')){
      setOrder(JSON.parse(sessionStorage.getItem('order')));
    }
    if(sessionStorage.getItem('expanded')){
      setExpanded(JSON.parse(sessionStorage.getItem('expanded')));
    }
    if(sessionStorage.getItem('selectedGenres')){
      setSelectedGenres(JSON.parse(sessionStorage.getItem('selectedGenres')));
    }
    if(sessionStorage.getItem('typeFilter')){
      setTypeFilter(JSON.parse(sessionStorage.getItem('typeFilter')));
    }
  },[]);

  useEffect(()=> {
    sessionStorage.setItem('rateFilter', JSON.stringify(rateFilter));
    sessionStorage.setItem('order', JSON.stringify(order));
    sessionStorage.setItem('expanded', JSON.stringify(expanded));
    sessionStorage.setItem('selectedGenres', JSON.stringify(selectedGenres));
    sessionStorage.setItem('typeFilter', JSON.stringify(typeFilter));
  },[typeFilter, rateFilter, order, expanded, selectedGenres])

  function slideStop (value) {
    setRateFilter(value.target.value);
  }

  function onSelectOrder (selectedOrder) {
      setOrder(selectedOrder.target.value);
  }

  const updateType = (e) => {
    !e.target.checked ? setTypeFilter(typeFilter.filter((each) => each !== parseInt(e.target.value))) : setTypeFilter([...typeFilter, parseInt(e.target.value)]);

    console.log(typeFilter.filter((each) => each !== '2'));
  }

  const switchExpanded = () => {
    setExpanded(() => !expanded);
  };



  return(
    <>
      <h1>{userName}</h1>
      <Container fluid className='filterBox'>
        <Form>
          <Row >
            <Col xs={4} md={1} xl={1}>Sorteer:</Col>
            <Col xs={7} md={4} xl={3}>
              <select onChange={onSelectOrder}>
                <option key='listorderlastseen' value='LastViewed'>Laatst gezien</option>
                <option key='listordertitel' value='TitleAsc'>Titel oplopend</option>
                <option key='listorderyear' value='YearDesc'>Jaartal film aflopend</option>
                <option key='listorderunique' value='Unique'>Unieke titels</option>
              </select>
            </Col>
            <Col xs={2} md={1} xl={1}>Score: </Col>
            <Col xs={7} md={6} xl={3}><ReactBootstrapSlider
              slideStop={slideStop}
                value={rateFilter}
                step={0.5}
                max={5}
                min={0} />
            </Col>
            <Col xs={4} md={2} xl={1}>
              <Form.Check
               inline
               id='Moviecb'
               type='checkbox'
               name='Type'
               value='1'
               label='Films'
               checked={typeFilter.indexOf(1)!==-1}
               onChange={updateType}
              /></Col>
              <Col xs={4} md={2} xl={1}>
              <Form.Check
               inline
               id='Shortcb'
               type='checkbox'
               name='Type'
               value='2'
               label='Shorts'
               checked={typeFilter.indexOf(2)!==-1}
               onChange={updateType}
              /></Col>
              <Col xs={3} md={2} xl={1}>
              <Form.Check
               inline
               id='Seriescb'
               type='checkbox'
               name='Type'
               value='3'
               label='Series'
               checked={typeFilter.indexOf(3)!==-1}
               onChange={updateType}
              />
            </Col>
            <Col xs={1} md={6} xl={1} className='allignRight'>{expanded ?  <CaretUpFill onClick={switchExpanded}/> : <CaretDownFill onClick={switchExpanded}/> }
            </Col>
          </Row>
          {expanded ?
            <Row >
              <GenreFilter setSelectedGenres={setSelectedGenres} selectedGenres={selectedGenres}/>
            </Row>
          : <></> }
        </Form>
      </Container>
      <ViewedMoviesGrid key={userId+rateFilter[0]+rateFilter[1]+order+selectedGenres+typeFilter} type={`getViewed`} order={order} showLastViewedBy={'true'} selectedGenres={selectedGenres} typeFilter={typeFilter} minRate={rateFilter[0]} maxRate={rateFilter[1]} nrOfRows={18} selectedUserId={userId} />
    </>
  );
};

export default UserPage;
