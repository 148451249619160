import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER
};

const Root = () => (
  <AlertProvider template={AlertTemplate} {...options}>
    <App />
  </AlertProvider>
)

ReactDOM.render( <Root />, document.getElementById("root"));
