import { useEffect} from 'react';
import MovieSearchResultList from './MovieSearchResultList';
import { useApi } from '../api/useApi';
import { fetchResource } from '../api/api';

const MoviesByDirector = (props) => {
  const [movies, setMovies] = useApi(() => fetchResource('moviesByDirector/'+props.directorId));
  // This function is called whenever the props variable is changed.
  useEffect(()=>{
    if(props.directorId){
      setMovies();
    }
  },[props]);

  return (
    <div>
      <MovieSearchResultList sort={props.sort} movies={movies.data ? movies.data : []} />
    </div>
  );
};

export default MoviesByDirector;
