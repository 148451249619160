import { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useApi } from '../api/useApi';
import { postResource } from '../api/api';

const AddComment = ({titleId, onAddComment}) => {
  const [addCommentResult, setAddCommentResult] = useApi(() => postResource('addComment', { titleId: titleId, comment: addComment }));
  const [addComment, setAddComment] = useState('');

  // If the  form is submitted, do not submit the from via the browser, but do the postResource
  const onSubmit = (e) =>{
    e.preventDefault();
    setAddCommentResult();
  };

  // if the postResource went OK, update the list of comments to include the newly added comment and empty the input box.
  useEffect(()=>{
    // First get info of movie, then get director info.
    if (addCommentResult.isSuccess){
      onAddComment(addComment);
      setAddComment('');
    }
  },[addCommentResult]);

  // if the content of the text input box changes, also change the addComment variable.
  const handleChange = (e) => {
    setAddComment(e.target.value);
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Label>Opmerking:</Form.Label>
      <Form.Control as="textarea" rows={3} value={addComment} onChange={handleChange}  />
      <Button variant="primary" type="submit">
        Plaats opmerking.
      </Button>
    </Form>
  );
};

export default AddComment;
