import { useEffect, useContext, useState} from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import MovieSearchResultList from './MovieSearchResultList';
import { useApi } from '../api/useApi';
import { postResource } from '../api/api';
import UserContext from './UserContext';
import GenreFilter from './GenreFilter';
import { useNavigate } from 'react-router-dom';

import ReactBootstrapSlider from 'react-bootstrap-slider';
import "bootstrap-slider/dist/css/bootstrap-slider.css"

const Inspiration = () => {
  const [userContext] = useContext(UserContext);
  const {settings} = useParams();
  const [url, setUrl] = useState('GetInspiration/');
  const [movies, setMovies] = useApi(() => postResource(url, post));
  const [post, setPost] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [rateFilter, setRateFilter] = useState([7,10]);
  const [lengthFilter, setLengthFilter] = useState([60,150]);
  const [seenByUser, setSeenByUser] = useState(0);
  const [seenBySomeone, setSeenBySomeone] = useState(0);
  const [pageLoad, setPageLoad] = useState(true)
  const navigate = useNavigate();

  useEffect(()=>{
    try {
      if(pageLoad){
        var decoded = JSON.parse(atob(settings));
        setUrl(decoded.url);
        setSelectedGenres(decoded.selectedGenres);
        setRateFilter([decoded.minRate, decoded.maxRate]);
        setSeenByUser(decoded.seenByUser);
        setSeenBySomeone(decoded.seenBySomeone);
        setLengthFilter([decoded.minLength, decoded.maxLength]);
      }
    } catch(e) {
    }
  },[settings]);

  useEffect(()=>{
    setPost({ selectedGenres: selectedGenres, minRate:rateFilter[0], maxRate:rateFilter[1], minLength:lengthFilter[0], maxLength:lengthFilter[1], seenByUser:seenByUser, seenBySomeone:seenBySomeone });
  },[selectedGenres, rateFilter, lengthFilter, seenByUser, seenBySomeone, url]);

  useEffect(() => {
    // If the page is first loaded, with a url that contains settings (back button in browser), then check if all the current state of the post variable is the same as the settings
    // i.e. all the states have been updated
    // If so, get the movies from the API
    // The pageLoad variable is used to make sure that this is only done at page load
    if(pageLoad){
      var tempPost = post
      tempPost.url = url;
      if(settings===btoa(JSON.stringify(tempPost))){
        setMovies();
        setPageLoad(false);
      }
    }
  },[post])


  const onSelectList = (selectedOption) => {
    setUrl(selectedOption.target.value);
    if(selectedOption.target.value==='GetInspiration/'){
      setRateFilter([7,10]);
      setSeenByUser(0);
      setSeenBySomeone(0);
    } else {
      setRateFilter([0,10]);
    }
  };

  function slideStopRate (value) {
    setRateFilter(value.target.value);
  };
  function slideStopLength (value) {
    setLengthFilter(value.target.value);
  };

  const onClickGoButton = () => {
    if (!userContext.userName){
      setSeenByUser(-1);
      setSeenBySomeone(-1);
    }
    var temp=post;
    temp.url = url;
    navigate("/Inspiration/" + btoa(JSON.stringify(temp)));
    setMovies();
  };

  function anonymousDefaults(){
    if(!userContext.userName && seenByUser!==-1 && seenBySomeone!==-1 && url==='GetInspiration/'){
      setSeenByUser(-1);
      setSeenBySomeone(-1);
      setUrl('getList/' +userContext.inspirationLists[0].Id);
    }
    return(<></>);
  }

  function seenFilters() {
    return(
      <Row>
        <Col>Gezien?</Col>
        <Col>Zelf:
          <Form.Check type='radio' disabled={url.includes('Inspiration')} name="self" checked={seenByUser===0} label='Niet gezien' value='0' id='self0' onClick={e => { setSeenByUser(Number(e.target.value)) }} />
          <Form.Check type='radio' disabled={url.includes('Inspiration')} name="self" checked={seenByUser===1} label='Wel gezien' value='1' id='self1' onClick={e => {setSeenByUser(Number(e.target.value))}}/>
          <Form.Check type='radio' disabled={url.includes('Inspiration')} name="self" checked={seenByUser===-1} label='Alles' value='-1' id='self-1' onClick={e => {setSeenByUser(Number(e.target.value))}}/>
        </Col>
        <Col>Anderen:
          <Form.Check type='radio' disabled={url.includes('Inspiration')} name="others" checked={seenBySomeone===0} label='Niet gezien' value='0' id='others0' onClick={e => {setSeenBySomeone(Number(e.target.value))}}/>
          <Form.Check type='radio' disabled={url.includes('Inspiration')} name="others" checked={seenBySomeone===1} label='Wel gezien' value='1' id='others1'onClick={e => {setSeenBySomeone(Number(e.target.value))}}/>
          <Form.Check type='radio' disabled={url.includes('Inspiration')} name="others" checked={seenBySomeone===-1} label='Alles' value='-1' id='others-1' onClick={e => {setSeenBySomeone(Number(e.target.value))}}/>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Container fluid className='filterBox'>
        <Form>
          <Row >
            <Col xs={4} md={4} xl={1}>Inspiratie vanuit:</Col>
            <Col xs={7} md={7} xl={3}>
            <select onChange={onSelectList} value={url} className='w-75'>

              { userContext.userName ? <option key='InspirationMagic' value={'GetInspiration/'}>Magische lijst</option> : <></> }
              { userContext.inspirationLists ? userContext.inspirationLists.map((list, index) => {

                return (
                  <option key={'Inspiration'+index} value={'getList/' + list.Id }>{list.Name} ({list.NrOfTitles})</option>
                )}) : <></> }
            </select>
            </Col>
            <Col xs={4} md={4} xl={1}>IMDB Score: {rateFilter[0]}-{rateFilter[1]} </Col>
            <Col xs={7} md={7} xl={3}><ReactBootstrapSlider
              slideStop={slideStopRate}
                value={rateFilter}
                step={0.5}
                max={10}
                min={0} />
            </Col>
            <Col xs={4} md={4} xl={1}>Lengte: {lengthFilter[0]}-{lengthFilter[1]} </Col>
            <Col xs={7} md={7} xl={3}><ReactBootstrapSlider
              slideStop={slideStopLength}
                value={lengthFilter}
                step={10}
                max={300}
                min={10} />
            </Col>
          </Row>
          { userContext.userName ? <>{seenFilters()}</> : <>{anonymousDefaults()}</> }

          <Row >
            <GenreFilter setSelectedGenres={setSelectedGenres} selectedGenres={selectedGenres}/>
          </Row>
          <Row>
            <Col>
              <Button variant="primary" onClick={onClickGoButton}>Go!</Button>
            </Col>
          </Row>
        </Form>
      </Container>
      <Container fluid>
        <Row>
          {movies.data ? <MovieSearchResultList movies={movies.data.Movies ? movies.data.Movies : []} /> : <></>};
          </Row>
      </Container>
    </>
  );
};

export default Inspiration;
