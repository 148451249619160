import axios from "axios";

var currentUrl = window.location.href;

var baseURL = 'https://api.ha-pe-em.nl/';
if(currentUrl.includes('test')){
  baseURL = 'https://dev-api.ha-pe-em.nl/';
}

// Make sure cookies are sent with the request (containing session id, so the back-end knows if the user is logged-in)
axios.defaults.withCredentials = true;

// Handles GET Requests
export const fetchResource = async (resource) => {
  try {
    const response = await axios.get(`${baseURL}/${resource}`);
    return await response.data;
  } catch (err) {
    throw err;
  }
};

// Handles POST Requests
export const postResource = async (resource, postArr) => {
  try {
    const response = await axios.post(`${baseURL}${resource}`, JSON.stringify(postArr), { headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }});
    return response.data;
  } catch (err) {
    console.log(`${err}`);
    throw err;
  }
};
