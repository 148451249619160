import SearchTitleResults from './SearchTitleResults';
import SearchDirectorResults from './SearchDirectorResults';


const SearchResultsPage = () => {
  return (
    <div>
      <h1>Regisseurs</h1>
      <SearchDirectorResults />
      <h1>Films</h1>
      <SearchTitleResults seen={false} />
    </div>
  );
}

export default SearchResultsPage;
