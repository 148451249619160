import { useEffect, useState} from 'react';
import MovieCard from './MovieCard';
import { Container, Col, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { postResource } from '../api/api';


const ViewedMoviesGrid = (props) => {
  const [ movies, setMovies ] = useState([]);
  const [ hasMore, setHasMore ] = useState(true);

  // At loading of the component, load the first set of results.
  // To disable the infinte scroll when it is not needed, also set hasMore to false
  useEffect( () => {
    fetchMoreMovies();
    setHasMore(props.infiniteScroll);
  },[]);

  // This function will fetch the next set of movies. It will use the length of the current set of movies to determine the startrow.
  const fetchMoreMovies = async () => {
    var newMovies = await postResource(props.type, { userId:props.selectedUserId, order:props.order, nrOfRows:props.nrOfRows, fromRow: movies.length, minRate:props.minRate, maxRate:props.maxRate, selectedGenres:props.selectedGenres, typeFilter:props.typeFilter});
    // API still needs to return the total amount of results and then we can actually set the hasMore value more precisely.
    if (newMovies.Content.length===0){
      setHasMore(false);
    }
    setMovies(movies.concat(newMovies.Content));
  }


  return (

      <InfiniteScroll
        dataLength={ movies.length }
        next={fetchMoreMovies}
        hasMore={ hasMore }
        loader={<h4>Loading...</h4>}
      >
        <Container fluid>
          <Row>
            {movies ? movies.map((movie, index) => (
              <Col xs={6} md={4} xl={2} key={index}>
                <MovieCard key={index} movie={movie} showLastViewedBy={props.showLastViewedBy} />
              </Col>
            )): <div>No data</div>}
          </Row>
        </Container>
      </InfiniteScroll>

  )
};

export default ViewedMoviesGrid;

ViewedMoviesGrid.defaultProps = {
    nrOfRows: '6',
    infiniteScroll: true
  }
