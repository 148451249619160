import { React, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { HashRouter, Routes, Route } from 'react-router-dom';


//import { createBrowserHistory } from 'history';

import Navigation from './Components/Navigation';
import SearchResultsPage from './Components/SearchResultsPage';
import MoviesByDirectorPage from './Components/MoviesByDirectorPage';
import UserContext from './Components/UserContext';
import ViewedMoviesGrid from './Components/ViewedMoviesGrid';
import HomePage from './Components/HomePage';
import MovieDetailsPage from './Components/MovieDetailsPage';
import UserPage from './Components/UserPage';
import Lists from './Components/Lists';
import List from './Components/List';
import Waar from './Components/Waar';
import Inspiration from './Components/Inspiration';
import TipsPage from './Components/TipsPage';
import ScanLocalDisk from './Components/ScanLocalDisk';
import { useApi } from './api/useApi';
import { fetchResource } from './api/api';

import 'bootstrap/dist/css/bootstrap.css';

const App = () => {
  const [activeUser, setActiveUser] = useApi(() => fetchResource('getActiveUser'));
  const [userContext, setUserContext] = useState({userId:null, userName:null});


  // Get information, like the active user, wishlists, and list of all users.
  useEffect(()=>{
    setActiveUser();
  },[])

  // Once the user information is retrieved, store it in the user context
  useEffect(()=>{
    if(activeUser.data){
      setUserContext(state => ({...state, userId: activeUser.data.activeUserId}));
      setUserContext(state => ({...state, userName: activeUser.data.activeUserName}));
      setUserContext(state => ({...state, lists: activeUser.data.lists}));
      setUserContext(state => ({...state, inspirationLists: activeUser.data.inspirationLists}));
      setUserContext(state => ({...state, allUsers: activeUser.data.allUsers}));
      setUserContext(state => ({...state, nrOfUnSeenTips: activeUser.data.nrOfUnSeenTips}))
    }
  },[activeUser])

  // For authentication, we are using session cookies.
  // However, for the first request to the API, there is no session cookie yet, so the API uses the persistent cookie to create the session and log the user in.
  // In this process the persistent cookie is refreshed and the old one is invalidated.
  // This only works if the first request to the API is a single request, i.e. not 3 different requests at the same time, that all use the same persistent cookie.
  // because then, only one will work and the 2 other will fail, logging the user out and deleting the persistent cookie.
  // Therefore the application needs to wait for the first request (getActiveUser) to finish (and with it, logging the user in via the persistent cookie) before
  // doing the other requests.
  if(userContext.allUsers){
    return(

          <HashRouter>

            <Container fluid>
            <UserContext.Provider value={[userContext,setUserContext]}>

            <Navigation />
            <Routes>

              <Route path='/' exact element={<HomePage />} />
              <Route path='/LastViewed' element={<><h1>Laatst gezien:</h1><ViewedMoviesGrid type={`getViewed`} showLastViewedBy={'true'} nrOfRows={18}/></>} />
              <Route path='/Search/:search' element={<SearchResultsPage />} />
              <Route path='/Director/:directorId' element={<MoviesByDirectorPage />} />
              <Route path='/Movie/:movieId' element={<MovieDetailsPage />} />
              <Route path='/User/:userId/:userName' element={<UserPage />} />
              <Route path='/Lists' element={<Lists />} />
              <Route path='/List/:id' element={<List />} />
              <Route path='/Waar' element={<Waar />} />
              <Route path='/Inspiration/:settings' element={<Inspiration />} />
              <Route path='/Inspiration/' element={<Inspiration />} />
              <Route path='/Tips' element={<TipsPage />} />
              <Route path='/Scan' element={<ScanLocalDisk />} />
            </Routes>

            </UserContext.Provider>
          </Container>

        </HashRouter>
        );
      } else {
        return(<p>Loading</p>)
      }



};

//export const history = createBrowserHistory({
//    basename: process.env.PUBLIC_URL
//});

export default App;
