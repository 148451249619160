import { React, useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import UserContext from './UserContext';
import { useApi } from '../api/useApi';
import { postResource } from '../api/api';

const AddTipModal = (props) => {
  const [userContext] = useContext(UserContext);
  const [show, setShow] = useState(false);

  const [addTipResult, setAddTipResult] = useApi(() => postResource('addTip', { titleId: props.movie.Id,comment: comment, toUserIds: selectedUsers }));

  const handleClose = () => {
    setShow(false);
    setSelectedUsers([]);
    setComment('');
  }
  const handleShow = () => setShow(true);

  const [comment, setComment] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);

  const addTip = (event) => {
    setAddTipResult();
  }

  useEffect(()=>{
    // First get info of movie, then get director info.
    if (addTipResult.isSuccess){
      handleClose();
    }
  },[addTipResult]);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleUserSelect = (event) => {
    console.log(selectedUsers);
    if(event.currentTarget.checked){
      setSelectedUsers(selectedUsers.concat([event.currentTarget.name]));
    } else {
      setSelectedUsers(selectedUsers.filter(UserId => UserId !== event.currentTarget.name));
    }
    //console.log(selectedUsers);
  }

  return (
      <>
        <Button variant="primary" onClick={handleShow}>
          Tip {props.movie.Title} aan iemand
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Tip {props.movie.Title} aan iemand</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Label>Waarom?</Form.Label>
              <Form.Control as="textarea" rows={3} value={comment} onChange={handleCommentChange}  />
              <Form.Label>Tip wie?</Form.Label>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">


              {userContext.allUsers ? userContext.allUsers.filter(User => (User.Id !== 0 && User.Id !== userContext.userId)).map((User, index) => (<Form.Check key={"UCB"+index} name={User.Id} onChange={handleUserSelect} type="checkbox" label={User.Name} />)):''}
            </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Annuleer
            </Button>
            <Button variant="primary" onClick={addTip}>
              Stuur tip
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
};

export default AddTipModal;
