import {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import MovieSearchResultList from './MovieSearchResultList';
import { useApi } from '../api/useApi';
import { postResource } from '../api/api';

const SearchTitleResults = (props) => {
  const [movies, setMovies] = useApi(() => postResource('searchTitle', post));
  const [post, setPost] = useState([]);
  const [searchStr, setSearchStr] = useState('');
  const { search } = useParams();
  const seen = props.seen;

  useEffect(() => {

    // Try to extract a year from the searchstring (i.e. 4 numbers, starting with 19 or 20)
    const yearArr=search.match(/(-){0,1}( ){0,1}(\(){0,1}(19|20)[0-9]{2}(\)){0,1}$/);
    if (yearArr){
      var year = yearArr[0].replace(/\D/g,'');
    }
    setPost({ search:search.replace(/(-){0,1}( ){0,1}(\(){0,1}(19|20)[0-9]{2}(\)){0,1}$/,'') ,seen: seen, year: year });
    setSearchStr(search.replace(/(-){0,1}( ){0,1}(\(){0,1}(19|20)[0-9]{2}(\)){0,1}$/,''));
  }, [search, seen]);

  // This function is called whenever the searchStr variable is changed, so that is after the previous function which watches search and seen.
  useEffect(()=>{

    if (searchStr){
      setMovies();
    }
  },[searchStr,post]);

  return (
    <MovieSearchResultList movies={movies.data ? movies.data : []} />
  );
};

export default SearchTitleResults;
