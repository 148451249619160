import { useContext } from 'react';
import UserContext from './UserContext';
import { getUserNameById } from '../functions';
import { Col, Row } from 'react-bootstrap';

const Comment = ({comment}) => {
  const [userContext] = useContext(UserContext);
  return (

      <Row className="justify-content-md-center">
        <Col xs={2}>{comment.UserId!==userContext.userId ? <div className="commentContent">{getUserNameById(userContext.allUsers, comment.UserId)}</div> : <></>}</Col>
        <Col xs={8}>
          <div className="commentContent">{comment.Content}</div>
          <div>{new Date(Date.parse(comment.Date)).toLocaleDateString('nl-NL')}</div>
        </Col>
        <Col xs={2}>{comment.UserId===userContext.userId ? <div className="commentContent">{userContext.userName}</div> : <></>}</Col>
      </Row>

  );

};

export default Comment;
