import { useState, useEffect, useContext} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserContext from './UserContext';
import { useApi } from '../api/useApi';
import { fetchResource, postResource } from '../api/api';

const AddToListDropDown = ({titleId}) => {
  const navigate = useNavigate();
  const [userContext] = useContext(UserContext);
  const [selectedList,setSelectedList] = useState('');
  const [addToListResult, setAddToListResult] = useApi(() => postResource('addTitleToList', { listId: selectedList, titleId: titleId }));

  const addTitleToList = async (list) => {
    if(titleId){
      setSelectedList(list.target.value);
    }
  }

  useEffect(()=>{
    if(selectedList){
      setAddToListResult();
    }
  },[selectedList])

  useEffect(()=>{
    // We assume for now that a result means it went OK.
    if (addToListResult.data){
      navigate('/List/'+selectedList);

    }
  },[addToListResult]);



  return(
    <>
    { userContext.lists && userContext.userId ?
          <div className="mb-2">
            <select onChange={addTitleToList}>
              <option>Voeg toe aan lijst</option>
              {userContext.lists.map((List, index) => (<option key={'WLs' + index} value={List.Id}>{List.Name} ({List.NrOfTitles})</option>))}
            </select>
          </div>
          :<></>}
    </>
  );
}
export default AddToListDropDown;
