import { useContext, useState } from 'react';
import { Container } from 'react-bootstrap';
import UserContext from './UserContext';
import Comment from './Comment';
import AddComment from './AddComment';

const Comments = ({comments, titleId}) => {
  const [userContext] = useContext(UserContext);
  const [allComments, setAllComments] = useState(comments);
  const [key, setKey] = useState(allComments.length);

  // This function will newly added commments without doing a call to the API.
  const onAddComment = (comment) => {
    var arr = allComments;
    arr.push({ Content: comment, Id: Date.now(), UserId: userContext.userId});
    setAllComments(arr);
    setKey(allComments.length);
  };

  return (
    <Container key={key}>
      {allComments.map((ComentContent,index) => (
        <Comment key={'Comments'+index} comment={ComentContent}/>
      ))}
    {userContext.userId ? <AddComment titleId={titleId} onAddComment={onAddComment}/> : <></>}
    </Container>
  );

};

export default Comments;
