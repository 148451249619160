import { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Type } from 'react-bootstrap-table2-editor';
import { postResource } from '../api/api';
import {scanFolder} from '../functions';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const ScanLocalDisk = () => {
  const [data,setData] = useState([]);
  const [updateTstamp, setUpdateTstamp] = useState( Date.now());
  var results = [];
  var files = [];
  var movies=null;


  const selectFolder = async () => {
    files = await scanFolder();
    for (const file of files) {
      movies = await postResource('searchTitle', { search: file.titleName, year: file.year });
      var titleSelected=0;
      if(movies.Content.length>0){
        titleSelected=movies.Content[0].Id.toString();
      } else {
        titleSelected='0';
      }
      results.push({fileName:file.fileName, fileTitleName:file.titleName, fileYear:file.year, titlesFound:movies.Content, titleSelected:titleSelected});
    };
    setData(results);
    // Make sure the table updates
    setUpdateTstamp(Date.now());
  }

  const columns = [
    {
      dataField: 'fileTitleName',
      text: 'Naam film vanuit bestandsnaam',
      editable: true
    },
    {
      dataField: 'fileYear',
      text: 'Jaartal in bestandsnaam'
    },
    {
      dataField: 'titleSelected',
      text: 'Selecteer titel',
      editable: true,
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions,{ row, column }) => {
          var result=[];
          if(row.titlesFound.length>0){
            row.titlesFound.map((Title, index)=>{result.push({value:Title.Id, label:Title.Directors[0].Name + ' - ' + Title.Title + ' (' + Title.Year + ')'});return true});
          } else {
            result.push({value:'0', label:'Geen titel gevonden, wijzig de titel of jaartal', selected:true});
          }
          return(result);
        }
      },
      formatter: FoundTitlesCellFormater
    },
  ];


  function FoundTitlesCellFormater(cell, row, rowIndex, formatExtraData) {
     return (
       <>{row.titlesFound ? row.titlesFound.map((Title, index) => (<div key={'FoundTitles' + Title.Id + rowIndex}>{Title.Id.toString()===row.titleSelected ? <strong> {Title.Directors[0].Name} - {Title.Title} ({Title.Year})</strong>:<>{Title.Directors[0].Name} - {Title.Title} ({Title.Year})</>}</div>)):<></>}</>
     );
  };

  async function afterSaveCell (oldValue, newValue, row, column) {
     if(column.dataField==='fileTitleName' || column.dataField==='fileYear'){
       var tempData=data;
       var foundMovies=[];
       movies = await postResource('searchTitle/'+row.fileTitleName, { year: row.fileYear });
       tempData.forEach((item, i) => {
         if(item.fileName===row.fileName){
           item.titlesFound=movies.Content;
           if(movies.Content.length>0){
             item.titleSelected=movies.Content[0].Id.toString();
           } else {
             item.titleSelected='0'
           }
         }

       });

       setData(tempData);
       // Make sure the table updates
       setUpdateTstamp(Date.now());
     }
   }

   const save = async () => {
     postResource('loadDiskScan/', { movies: data, delete:false });

   }


  return (
    <Container>
      <Form>
        <Button variant="primary" type="button" onClick={selectFolder}>
          Selecteer folder.
        </Button>
      </Form>
      <BootstrapTable key={updateTstamp} keyField='fileName' data={ data } columns={ columns }  striped condensed={true} bootstrap4={true}  cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true, afterSaveCell: afterSaveCell}) }/>
      <Form>
        <Button variant="primary" type="button" onClick={save}>
          Sla op.
        </Button>
      </Form>
    </Container>
  );
};

export default ScanLocalDisk;
