import {  Col, Form } from 'react-bootstrap';

const GenreFilter = (props) => {

  const genres =  [
    "Action",
    "Adventure",
    "Animation",
    "Biography",
    "Comedy",
    "Crime",
    "Documentary",
    "Drama",
    "Family",
    "Fantasy",
    "Film-Noir",
    "History",
    "Horror",
    "Music",
    "Musical",
    "Mystery",
    "Romance",
    "Sci-Fi",
    "Short",
    "Sport",
    "Thriller",
    "War",
    "Western"
  ];
// Excluded:      "Adult",   "Game-Show",    "News",    "Reality-TV",    "Talk-Show",

 const updateSelectedGenres = (e) => {
   !e.target.checked ? props.setSelectedGenres(props.selectedGenres.filter((each) => each !== e.target.value)) : props.setSelectedGenres([...props.selectedGenres, e.target.value]);
 }

 const updateAll = (e) => {
   if (e.target.checked){
     props.setSelectedGenres([])
   }
 }

 //useEffect( () => {
//   props.updateSelectedGenres(selectedGenres);
 //},[selectedGenres]);

    return(
      <>
        <Col xs={12}>
          <strong>Genres: </strong>
          <Form.Check type='checkbox' id='allcheckbox' label='Alle genres' checked={props.selectedGenres.length===0} onChange={updateAll}/>
        </Col>
        {genres.map((genre, index) => {
          return(<Col xs={6} md={3} lg={2} key={genre}><Form.Check
           type='checkbox'
           id={index}
           label={genre}
           value={genre}
           checked={props.selectedGenres ? props.selectedGenres.indexOf(genre)!==-1 : 0}
           onChange={updateSelectedGenres}
          /></Col>)
          })}
      </>
    );
}
export default GenreFilter;
