import ViewedMoviesGrid from './ViewedMoviesGrid';
import { useParams} from "react-router-dom";

const Homepage = () => {
  const { id, name } = useParams();


  return (

    <>
    <h3>Laatst gezien
    {name ? ' door: ' + name : ' door de filmavond :'}
    </h3>
    <ViewedMoviesGrid type={`getViewed`} showLastViewedBy={'true'} nrOfRows={6} selectedUserId={id} infiniteScroll={false}/>
    <h3>Willekeurig:</h3>
    <ViewedMoviesGrid type={`getViewed`} order={'Random'} nrOfRows={18} selectedUserId={id} infiniteScroll={false}/></>
);
};

export default Homepage;
