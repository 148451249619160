import React from 'react';

const UserContext = React.createContext([{}, () => {}]);

// UserContext contains the following elements:
// userId: the id of the active (logged-in) user
// userName: the name of the active (logged-in) user
// Lists: an array with the Lists
// allUsers: an array with the al users and their ids, nr of movies etc.
// nrOfUnSeenTips: The number of unseen Tips


export default UserContext;
