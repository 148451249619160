import { useEffect} from 'react';
import { useParams } from 'react-router-dom';
import MoviesByDirector from './MoviesByDirector';
import { useApi } from '../api/useApi';
import { fetchResource } from '../api/api';

const MoviesByDirectorPage = () => {
  const { directorId } = useParams();
  const [directorName, setDirectorName] = useApi(() => fetchResource('directorName/'+directorId));

  // This function is called whenever the search variable is changed.
  useEffect(()=>{
    if(directorId){
      setDirectorName();
    }
  },[directorId]);


  return (
    <div>
      {directorName.data ? <h1>{ directorName.data.Name }</h1>:''}
      <MoviesByDirector directorId={directorId} />
    </div>
  );
};

export default MoviesByDirectorPage;
