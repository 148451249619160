export const getUserRateFromMovie = (movie, userId, nullvalues=true) => {
  if (movie.Rating) {
    let found = movie.Rating.find(o => o.UserId === userId);
    if(found){
      return found.Rate;
    } else {
      if(nullvalues){
        return null;
      } else {
        return -1;
      }
    }
  } else {
    if(nullvalues){
      return null;
    } else {
      return -1
    }
  }
};

export const getUserNameById = (userList, userId) => {
  if (userList){
    var user = userList.find(({ Id }) => Id === userId );
    if(user){
      return user.Name;
    } else {
      return '';
    }

  }
  return '';
}

export const formatRate = (rate) => {
  if(rate===0){
    return 'Gezien, geen cijfer';
  }
  return rate
};


async function* getFilesRecursively (entry, currentPath) {
  if (entry.kind === 'file') {
    const file = await entry.getFile();
    if (file !== null) {
      //ile.relativePath = getRelativePath(entry);
      file.relativePath = currentPath;
      file.folderName = currentPath.substr(0,currentPath.lastIndexOf("/")).substr(currentPath.substr(0,currentPath.lastIndexOf("/")).lastIndexOf("/")+1);
      yield file;
    }
  } else if (entry.kind === 'directory') {
    for await (const handle of entry.values()) {
      yield* getFilesRecursively(handle, currentPath+"/"+handle.name);
    }
  }
}




export const scanFolder = async () =>{
  // This function will open a popup to select a folder to scan
  // It will return a list of movie files for which there is a title and year in the file or foldername.

  const dirHandle= await window.showDirectoryPicker();
  //console.log(dirHandle);
  const result = await getFilesRecursively(dirHandle, "/" + dirHandle.name);
  //console.log(result);
  var found = [];
  var name = '';
  for await (const entry of result) {
    //console.log(Object.prototype.toString.call(entry))
    if (Object.prototype.toString.call(entry) !== '[object File]') {
      continue;
    }
    // Search for movie files.
    // Then search for files with 19NN or 20NN in the string or in the foldername.
    if(entry.name.match('^.*(mkv|mp4|avi|mov|iso)$')){
      //console.log(entry.name);
      if(entry.name.match('^.*[.([ _](19|20)[0-9]{2}.*$')){
        name=entry.name;
        //console.log("Year found in file name");
      } else if (entry.folderName.match('^.*[.([ _](19|20)[0-9]{2}.*$')){
        //console.log("Year found in folder name");
        name=entry.folderName;
      } else {
        console.log("Moviefile found, but no year in file or folder name: " + entry.name);
        continue;
      }
    } else {
      continue;
    }

    var fileName=entry.relativePath;
    // Only take the first part of the string, so everything up to and including the 19NN,20NN
    name = name.substr(0,name.search("[.([ _](19|20)[0-9]{2}")+5);
    //extract the year
    var year = name.substr(name.length-4,4);
    // remove the year from the name
    name = name.substr(0, name.length - 5);
    //replace dots and underscores with spaces
    name = name.replaceAll("."," ");
    name = name.replaceAll("_", " ");
    // don't and if the movie is already in the found list (CD1, CD2 for instance)
    let check = found
      .map(item => item.titleName + item.year)
      .includes(name+year);
    if (!check){
      found = [...found, {fileName:fileName, titleName:name,year:year,size:entry.size}];
    }
  }

 return found;
}
