import { useState } from 'react';
import { useAlert } from "react-alert";

export function useApi(apiFunction) {
  const alert = useAlert()
  const [response, setResponse] = useState({
    data: false,
    isFetching: false,
    error: null,
    isSuccess: false
  });

  const fetchMethod = () => {
    setResponse({
      data: false,
      isFetching: true,
      error: null,
      isSuccess: false
    });

    apiFunction()
      .then(res => {
        if(res.Content==null){res.Content=false}
        setResponse({
          ...response,
          data: res.Content,
          isFetching: false,
          isSuccess: true
        })
      })
      .catch(err => {
        setResponse({
          ...response,
          isFetching: false,
          error: err.message,
          isSuccess: false
        })
        if (err.response.data.Errors){
          alert.error(err.response.data.Errors)
        }
      })
  };

  return [response, fetchMethod];
}
