import { useEffect, useState, useContext } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Form } from 'react-bootstrap';
import UserContext from './UserContext';
import { useApi } from '../api/useApi';
import { postResource } from '../api/api';

const Lists = (props) => {
  const [userContext, setUserContext] = useContext(UserContext);
  const [privateLists, setPrivateLists] = useApi(() => postResource('getLists', {type:0, active:true}));
  const [publicLists, setPublicLists] = useApi(() => postResource('getLists', {type:2, active:false}));
  const [addResponse, setAddResponse] = useApi(() => postResource('addList',{ name: newListName }));
  const [deleteResponse, setDeleteResponse] = useApi(() => postResource('deleteList/'+deleteId,{}));
  const [deleteId, setDeleteId] = useState('');
  const [newListName, setNewListName] = useState('');

  useEffect(()=>{
    setPrivateLists();
    setPublicLists();
  },[]);

  useEffect(()=>{
    if (addResponse.isSuccess){
      setPrivateLists();
    }
  },[addResponse]);

  useEffect(()=>{
    if (deleteResponse.isSuccess){
      setPrivateLists();
    }
  },[deleteResponse]);

  useEffect(()=>{
    if (deleteId){
      setDeleteResponse();
    }
  },[deleteId]);

  useEffect(()=>{
    // Once we've added a list, we need to update the global context to include the new list, so that it is shown in the dropdowns
    if (privateLists.isSuccess & publicLists.isSuccess){
      setUserContext(state => ({...state, lists: [...privateLists.data, ...publicLists.data.filter(list => list.Active===1)]}));
    }
  },[privateLists, publicLists]);



  const columns = [{
    dataField: 'Id',
    hidden: true
  },{
    dataField: 'Name',
    text: 'Naam',
    Sort: true,
    formatter: NameCellFormater
  },{
    dataField: 'NrOfTitles',
    text: 'Aantal titels',
    Sort: true
  },{
    dataField: 'Id',
    text: 'Actie',
    Sort: false,
    formatter: listActionFormatter
  }]

  function NameCellFormater(cell, row, rowIndex, formatExtraData) {
    return (
      <div><a href={"/#/List/" + row.Id}>{row.Name}</a></div>
    );
  };

  function listActionFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div>
        {row.UserId!==0 ? <Button variant="primary" onClick={() => deleteList(row.Id)}>Verwijder</Button> : <></>}
      </div>
    );
  };

  function deleteList(id){
    setDeleteId(id);
  }

  function addList(e){
    e.preventDefault();
    setAddResponse();

  }





  return (
    <>
      <h2>Prive lijsten</h2>
      <BootstrapTable keyField='Id' data={ privateLists.data ? privateLists.data : [] } columns={ columns } striped condensed={true} bootstrap4={true}/>
      <Form onSubmit={addList}>
        <Form.Control  type="text" placeholder="Naam van nieuwe lijst."  value={newListName} onChange={(e) => setNewListName(e.target.value)}/>
        <Button variant="primary" type="submit">Voeg toe</Button>
      </Form>
      <h2>Gedeelde lijsten</h2>
      <BootstrapTable keyField='Id' data={ publicLists.data ? publicLists.data : [] } columns={ columns } striped condensed={true} bootstrap4={true}/>
    </>
  );
};

export default Lists;
