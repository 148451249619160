import { useState, useEffect } from 'react';
import { postResource } from '../api/api';


const RatingDropdown = (props) => {
  const [selectedRate, setSelectedRate] = useState('-');
  const onSaveRating = async (rate) => {

    if(rate.target.value==='-'){
      postResource('deleteRate', { titleId: props.titleId });
    } else {
      postResource('addRate', { titleId: props.titleId, rate: rate.target.value });
    }
    setSelectedRate(rate.target.value);
  };

  useEffect(()=>{
    if (props.rating){
      setSelectedRate(props.rating.toFixed(1));
    }
  },[props]);


  return (
    <div className="mb-2">Geef score:&nbsp;
    <select value={selectedRate} onChange={onSaveRating}>
      <option key={'RT'+props.titleId+'-'} value='-'>Nog niet gezien</option>
      <option key={'RT'+props.titleId+'-'+0} value='0.0'>Gezien, maar geen cijfer</option>
    {['0.5', '1.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0', '4.5', '5.0'].map((index) => {
      return (
        <option key={'RT'+props.titleId+'-'+index} value={index}>{index}</option>
      )
    })}
    </select></div>

  )
}

export default RatingDropdown;
