import { useEffect} from 'react';
import { useApi } from '../api/useApi';
import { fetchResource } from '../api/api';

const Waar = () => {
  // Added time to request to disable caching
  const [waar, setWaar] = useApi(() => fetchResource('waar.json?'+Date.now()));

  useEffect(()=>{
    setWaar();
  },[]);

  return (

    <>
      <pre>
        {waar.data.Waar}
      </pre>
    </>

  );
};

export default Waar;
