import { useEffect, useState} from 'react';
import { useApi } from '../api/useApi';
import { postResource } from '../api/api';
import MovieSearchResultList from './MovieSearchResultList';


const TipsList = () => {
  const [tips, setTips] = useApi(() => postResource('getTips', {seen:1, markAllAsSeen:true}));
  const [deleteResponse, setDeleteResponse] = useApi(() => postResource('deleteTip/'+deleteId,{}));
  const [deleteId, setDeleteId] = useState('');

  useEffect(()=>{
    if (deleteId){
      setDeleteResponse();
    }
  },[deleteId]);

  useEffect(()=>{
    if (deleteResponse.isSuccess){
      setTips();
    }
  },[deleteResponse]);

  useEffect(()=>{
      setTips();
  },[]);
 //<BootstrapTable keyField='Id' data={ tips.data } columns={ columns } striped condensed={true} bootstrap4={true}/> :

  return (
    <>{ tips.data ? <MovieSearchResultList movies={tips.data ? tips.data : []} showTips={true} setDeleteTip={setDeleteId} /> : <></>}</>
  );
}

export default TipsList;
