import { useEffect, useContext} from 'react';
import { Container, Col, Row, Image } from 'react-bootstrap';
import { BoxArrowUpRight, CardList } from 'react-bootstrap-icons'
import { Tooltip } from "react-tooltip";
import RatingDropdown from './RatingDropdown';
import Comments from './Comments';
import {getUserRateFromMovie, formatRate} from '../functions';
import UserContext from './UserContext';
import { useApi } from '../api/useApi';
import { fetchResource } from '../api/api';
import 'react-tooltip/dist/react-tooltip.css';

const MovieDetails = (props) => {
  const [userContext] = useContext(UserContext);
  const [movieMeter, setMovieMeter] = useApi(() => fetchResource('getMovieMeterDetails/'+props.movie.Id));

  // Retrieve information from MovieMeter, but only for logged-in users and for movies.
  useEffect(()=>{

    if (userContext.userId && props.movie.Id ){
      setMovieMeter();
    }
  },[props.movie.Id]);

  function formatDate(date){
    date = new Date(Date.parse(date))
    if(isNaN(date.getTime())){
      return(<></>)
    } else {
      return('(' + date.toLocaleDateString('nl-NL') + ')')
    }
  }

  function userRates(ratings){

    if(props.movie.Rating.length===0){
      return(<p>Nog niet gezien door de Filmavond</p>)
    } else {
      return(<>
        {ratings.map((Rating, index) => (
          <p key={'RT-P'+index}>{Rating.Name} - {formatRate(Rating.Rate)} {formatDate(Rating.Date)} </p>
          ) ) }
        </>
      )
    }
  }

  return (
    <><h1>{props.movie.Title} ({props.movie.Year})</h1>
    <Container fluid className="mb-2">
      <Row><h3>{props.movie.OriginalTitle ? props.movie.OriginalTitle : <></> } </h3>
        <Col>
          { props.movie.Directors ? <>{props.movie.Directors.map((Director, index) => (<a key={'Directors'+Director.Id} href={"/#/Director/" + Director.Id}>{Director.Name + " "}</a>))}< />: <div />}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          {props.movie.Genres ? props.movie.Genres +' ': <></> }
          {props.movie.RunTime ? props.movie.RunTime + ' minuten' : <></>}
          &nbsp;<a href={'https://www.youtube.com/results?search_query='+props.movie.Title+' ('+props.movie.Year+') trailer'} target='_new'>Trailer <BoxArrowUpRight /></a>
        </Col>
      </Row>
      <Row>
        <Col md={6}><p>
          {movieMeter.data ?movieMeter.data.plot : <></>} {props.movie.Lists.length ? <><CardList data-tooltip-id={'List' + props.movie.Id} /><Tooltip id={'List'+props.movie.Id} place="top" effect="solid">{props.movie.Lists.map((List, index) => (<div key={'List'+List.Id.toString()}>{List.Name}</div>))}</Tooltip></> : <></>}</p>
        </Col>
      </Row>


      <Row className="justify-content-md-center">
        <Col md={6} xl={4}>
          <Image src={props.movie.URL} width="200px" rounded />
        </Col>
        <Col md={6} xl={4}>
          <h2>Scores</h2>
          {userContext.userId ? <RatingDropdown rating={getUserRateFromMovie(props.movie, userContext.userId)} titleId={props.movie.Id}/> : <></>}
          {userRates(props.movie.Rating)}
          <p><a href= {'https://www.imdb.com/title/tt' + props.movie.Id.toString().padStart(7,"0")}  target='_new'>IMDB</a>: {props.movie.IMDBRate} ({props.movie.IMDBNrOfVotes})</p>
          {movieMeter.data ? <p><a href={movieMeter.data.url} target='_new'>MovieMeter:</a> {movieMeter.data.average} ({movieMeter.data.votes_count})</p>: <></>}
        </Col>
        <Col md={12} xl={4}>
          {props.movie.Comments ? <Comments comments={props.movie.Comments} titleId={props.movie.Id}/> : <></>}
        </Col>
      </Row>
    </Container></>
  );
};

export default MovieDetails;
