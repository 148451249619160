import { React, useState, useEffect, useContext, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Container, Navbar, Nav, Form, Col } from 'react-bootstrap';
import { List as ListIcon} from 'react-bootstrap-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAlert } from "react-alert";
import { LinkContainer } from 'react-router-bootstrap';
import UserContext from './UserContext';
import UserDropdown from './UserDropdown';



const Navigation = (props) => {
  const [userContext] = useContext(UserContext);
  const [searchText, setSearchText] = useState('');
  const searchField = useRef(null);
  const alert = useAlert();
  const newTip = userContext.nrOfUnSeenTips;
  let navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  //const is

  // If the search form is submitted, do not do an actual post, but just update the URL.
  const onSubmit = (e) =>{
    e.preventDefault();
    if(searchText.length<3){
      alert.error("Zoek aub op meer dan 2 letters. Tip, je kan ook op regisseur zoeken.")
    } else {
      navigate("/Search/" + searchText);
    }
  }

  // Redirect to log-in page
  const onClickLogin = () =>{
    window.location.href = 'https://api.ha-pe-em.nl/Login?redirect='+encodeURIComponent(window.location.href);
  }

  // Redirect to log-out page
  const onClickLogout = () =>{
    window.location.href = 'https://api.ha-pe-em.nl/Logout?redirect='+encodeURIComponent(window.location.href);
  }

  // This function will make sure the search input field is updated if the user goes back/forward between different searches
  let location = useLocation();
  useEffect(() => {
    if(location.pathname.substr(1,6)==='Search'){
      setSearchText(decodeURIComponent(location.pathname.substr(8)));
    }
  }, [location]);

  // Set focus on search field, but not for mobile, because that is annoying for the user.
  useEffect(() =>{
    if(!isTabletOrMobile){
      searchField.current.focus();
    }
  })

  return (
    <Navbar collapseOnSelect bg="primary" variant="dark" expand="lg" sticky="top">
      <Container fluid className='thomascontainer'>
        <Col xs={2} lg={8}>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"><ListIcon />
          {newTip ? <div className="navbartip">{newTip}</div> : <></>}
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav" >
            <Navbar.Brand href="#">Filmavond</Navbar.Brand>
             <Nav className="me-auto my-2 my-lg-0" >
                <LinkContainer to="/LastViewed"><Nav.Link >Laatst gezien</Nav.Link></LinkContainer>
                <LinkContainer to="/Lists"><Nav.Link >Lijsten</Nav.Link></LinkContainer>
                <LinkContainer to="/Tips"><Nav.Link>Tips   {newTip ? <div class="navbartip">{newTip}</div> : <></>}</Nav.Link></LinkContainer>
                <LinkContainer to="/Waar"><Nav.Link >Waar?</Nav.Link></LinkContainer>
                <LinkContainer to="/Inspiration/"><Nav.Link >Inspiratie</Nav.Link></LinkContainer>
                {userContext.userName ? <Nav.Link  onClick={onClickLogout}>{userContext.userName}</Nav.Link>: <Nav.Link onClick={onClickLogin}>Login</Nav.Link>}
             </Nav>
          </Navbar.Collapse>
        </Col>
        <Col xs={6} lg={2}  className='thomasselect'>
          <UserDropdown />
        </Col>
        <Col xs={4} lg={2}>
          <Form onSubmit={onSubmit} >
            <Form.Control ref={searchField} type="text" placeholder="Zoek op filmnaam of regisseur"  value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
          </Form>
        </Col>
      </Container>
    </Navbar>
  );
};

export default Navigation;
