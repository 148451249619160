import TipsList from './TipsList';

const TipsPage = () => {
  return (
    <div>
      <h1>Tips</h1>
      <TipsList />
    </div>
  );
}

export default TipsPage;
