import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from './UserContext';

const UserDropdown = () => {
  const [userContext] = useContext(UserContext);
  const navigate = useNavigate();

  const onSelectUser = (selectedOption) => {
    navigate(selectedOption.target.value);
  }


  return(
    <select onChange={onSelectUser}>
      <option key='userdropdownhome' value='/'>Gebruikers</option>
      { userContext.allUsers ? userContext.allUsers.map((user, index) => {

        return (
          <option key={'userdropdown'+index} value={'/User/' + user.Id + '/' + user.Name}>{user.Name} ({user.NrOfFilms})</option>
        )}) : <></> }
    </select>
  );

}
export default UserDropdown;
