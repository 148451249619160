import { useContext } from 'react';
import { Card } from 'react-bootstrap';
import UserContext from './UserContext';
import { postResource } from '../api/api';
import { formatRate } from '../functions';
import { Tooltip } from "react-tooltip";
import { Tv } from 'react-bootstrap-icons';
import 'react-tooltip/dist/react-tooltip.css';

const MovieCard = ({movie, showLastViewedBy}) => {
  const [userContext] = useContext(UserContext);

  function LastViewedText (lastViewed) {
    return(
      <>{lastViewed[0].Name} : {formatRate(lastViewed[0].Rate)} ({new Date(Date.parse(lastViewed[0].Date)).toLocaleDateString('nl-NL')})</>
    )
  }

  return (
    <Card style={{ width: '133px', border:'none' }}>
      <div data-tooltip-id={movie.Id}>
        <Card.Img variant="top" src={movie.URL} width="200px" />
      </div>
      <Tooltip id={movie.Id.toString()}  data-tooltip-float="true">{movie.Rating.map((Rate, index) => (<div key={'isSeenRates'+movie.Id.toString() + Rate.Name.toString()}>{Rate.Name} - {formatRate(Rate.Rate)}</div>))}</Tooltip>
      <Card.Title>
        <a href={"/#/Movie/" + movie.Id}>{movie.Title} ({movie.Year})</a>{movie.Type!==1 ? <Tv /> : <></>}
      </Card.Title>
      <Card.Subtitle className="mb-2">
        {movie.Directors.slice(0, 5).map((Director, index) => (Director.Name + " "))}
      </Card.Subtitle>
      <Card.Text className="mb-2  text-muted">
        {showLastViewedBy ? <>{LastViewedText(movie.LastViewedBy)} <br/></>: <></>}
        <br/>
      </Card.Text>
    </Card>
  )
};

export default MovieCard;
